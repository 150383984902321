{
  "locale": {
    "en": "🇬🇧 English",
    "ru": "🇷🇺 Russian"
  },
  "nav": {
    "support": "🍺 Поддержать проект",
    "code": "👾 Исходный код",
    "theme": {
      "light": "Свет",
      "dark": "Мгла"
    },
    "attention": {
      "title": "🤖 Анонс!",
      "messageOne": "Я создал бота для Telegram, который автоматически публикует новости о компаниях:",
      "messageTwo": "Работает на последних моделях LLM для фильтрации и переписывания новостей. Бесплатный и с открытым исходным кодом."
    }
  },
  "mainWidget": {
    "website": "Сайт",
    "company": "Компания",
    "closestEarnings": "Ближайший отчёт",
    "reports": "Отчёты",
    "equityResearch": "Проф. обзор",
    "findResearch": "Найти",
    "error": "Ошибка! Тикер не найден!",
    "example": "Пример",
    "tickerPlaceholder": "Тикер",
    "disclaimer": "Данные Finviz обновляются каждые 20 минут, а Shortsqueeze каждые 12 часов. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "technicalAnalysis": {
    "title": "Технический анализ",
    "copyright": "Технический анализ от",
    "disclaimer": "Виджет TradingView отображается в реальном времени. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "tightshorts": {
    "title": "Объём шортов",
    "disclaimer": "Данные Tightshorts обновляются раз в день (после закрытия рынка). Не является ИИР и не может использоваться в коммерческих целях."
  },
  "options": {
    "title": "Опционы",
    "disclaimer": "Данные Barchart обновляются каждые 20 минут. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "analyst": {
    "title": "Мнение аналитиков",
    "disclaimer": "Данные Finviz обновляются каждые 20 минут, Barchart каждые 20 минут. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "netIncome": {
    "title": "Выручка и прибыль",
    "disclaimer": "Данные Barchart обновляются каждые 30 дней. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "insidersTrades": {
    "title": "Сделки инсайдеров",
    "table": {
      "date": "Дата",
      "transaction": "Транзакция",
      "insider": "Инсайдер",
      "value": "Сумма"
    },
    "disclaimer": "Данные Finviz обновляются каждые 20 минут. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "debtToEquity": {
    "title": "Долг к капиталу",
    "disclaimer": "Данные Barchart обновляются каждые 30 дней. Не является ИИР и не может использоваться в коммерческих целях."
  },
  "links": {
    "title": "Ссылки"
  },
  "reportsWarning": {
    "waring": "Внимание",
    "message": "Отчёт близко",
    "amc": "после закрытия рынка",
    "bmc": "до закрытия рынка"
  },
  "chart": {
    "copyright": "График от"
  },
  "footer": {
    "created": "Создано",
    "supportHeader": "Поддержать Short Fork"
  },
  "tags": {
    "main": {
      "pe": {
        "short": "P/E",
        "full": "Price / Earnings",
        "description": "отношение капитализации компании к её годовой прибыли."
      },
      "price": {
        "short": "Цена",
        "full": "Рыночная цена акции",
        "description": "это цена, по которой акция продаётся и покупается на бирже. Рыночная цена обычно формируется на торгах на фондовой бирже и отражает баланс спроса и предложения на данные акции."
      },
      "fpe": {
        "short": "Forward P/E",
        "full": "Forward Price / Net Income",
        "description": "будущий P/E. Отношение текущей капитализации компании к её будущей годовой прибыли (на основе прогноза)."
      },
      "dividend": {
        "short": "Дивиденд",
        "full": "Дивиденды",
        "description": "это часть прибыли или свободного денежного потока (FCF), которую компания выплачивает акционерам."
      },
      "ps": {
        "short": "P/S",
        "full": "Price / Sales Ratio",
        "description": "отношение капитализации компании к её объему продаж. P/S дает представление о том, сколько инвестор платит за $1 ее выручки."
      },
      "beta": {
        "short": "Beta",
        "full": "Бета-коэффициент (Beta) волатильности",
        "description": "Если Бета равна единице, это значит, что акция колеблется вместе с рынком и ее риск эквивалентен рыночному. Значение больше чем 1 указывает на то, что цена ценной бумаги будет более волатильной, чем рынок."
      },
      "pb": {
        "short": "P/B",
        "full": "Price / Book Value",
        "description": "показывает отношение рыночной цены акции к балансовой стоимости. Инвесторы с помощью P/B хотят понять, сколько они платят за чистые активы компании."
      },
      "sv": {
        "short": "SV за 1 день",
        "full": "TightShort.ru Short Volume",
        "description": "процент шортовых позиций по сделкам за прошлый день по данным TightShort.ru. Выражает интерес инвесторов к шорту конкретных акций в последний торговый день."
      },
      "peg": {
        "short": "PEG",
        "full": "Price to Earnings Growth",
        "description": "Мультипликатор PEG показывает, какую цену готов заплатить инвестор за рост компании в будущих периодах."
      },
      "sfs": {
        "short": "Short Float S",
        "full": "Squeeze Short Float",
        "description": "это процентный показатель, он считается от общего количества акций в свободном обращении и показывает какое количество акций сейчас торгуется в шорт (по данным shortsqueeze.com)."
      },
      "roe": {
        "short": "ROE",
        "full": "Return on Equity",
        "description": "отношение чистой прибыли к собственному капиталу компании. Показывает, сколько прибыли генерирует капитал эмитента. Позволяет оценить эффективность и потенциальную доходность бизнеса."
      },
      "sff": {
        "short": "Short Float F",
        "full": "Finviz Short Float",
        "description": "это процентный показатель, он считается от общего количества акций в свободном обращении и показывает какое количество акций сейчас торгуется в шорт (по данным finviz.com)."
      },
      "roa": {
        "short": "ROA",
        "full": "Return on Assets",
        "description": "это отношение чистой прибыли к активам компании. Показывает способность компании эффективно использовать имеющиеся у нее активы для создания прибыли. Также данный показатель отражает среднюю доходность, получаемую на все источники капитала — собственного и заемного."
      },
      "sr": {
        "short": "Short Ratio",
        "full": "Short-Interest Ratio",
        "description": "количество акций в шорте (short float) поделённые на средний дневной торговый объём. Представляет собой среднее количество дней необходимое шортистам для покрытия позиций."
      },
      "de": {
        "short": "Debt/Eq",
        "full": "Debt to Equity ratio",
        "description": "отражает соотношение долга к собственному капиталу в активах компании. Если мультипликатор Debt/Equity компании выше 1, это означает, что ее активы составляет преимущественно долг, и наоборот, если он меньше 1 - основную часть активов компании составляет собственный капитал."
      },
      "country": {
        "short": "Страна",
        "full": "Страна",
        "description": "страна, в чьей юрисдикции находится компания."
      },
      "instOwn": {
        "short": "Inst Own",
        "full": "Процент владения институционалами",
        "description": "число акций во владении у институционалов поделённое на общее количество акций в обращении (в процентном соотношении)."
      },
      "targetPrice": {
        "short": "Целевая цена",
        "full": "Целевая цена %",
        "description": "разница между целевой ценой аналитиков и текущей ценой акции, выраженная в процентах. Как правило, целевую цену прогнозируют на год. Помните, что аналитики могут ошибаться или действовать из корыстных интересов."
      },
      "insiderOwn": {
        "short": "Insider Own",
        "full": "Процент владения инсайдерами",
        "description": "число акций во владении у инсайдеров поделённое на общее количество акций в обращении (в процентном соотношении)."
      },
      "rsi": {
        "short": "RSI (14)",
        "full": "Relative Strength Index",
        "description": "индекс относительной силы определяет силу рынка, соотнося средние цены закрытия периодов, в течение которых происходил рост, к средним ценам закрытия периодов во времена спада. На сайте значение RSI посчитано с периодом в 14 дней."
      }
    },
    "options": {
      "iv": {
        "short": "IV",
        "full": "Implied Volatility (IV)",
        "description": "средняя ожидаемая волатильность ближайшего месячного опционного контракта. IV это прогноз вероятности изменения цены базового актива, причем более высокий IV означает, что рынок ожидает значительного движения цены, а более низкий IV означает, что рынок ожидает, что цена базового актива останется в текущем торговом диапазоне."
      },
      "volatAvg": {
        "short": "Volat Avg",
        "full": "20-Day Historical Volatility",
        "description": "среднее отклонение от средней цены за последние 20 дней. Историческая волатильность - это измерение того, как быстро ценная бумага менялась в цене в прошлом."
      },
      "todVol": {
        "short": "Tod Vol",
        "full": "Today's Open Interest (OI)",
        "description": "общий открытый интерес по всем опционным контрактам (по всем датам истечения срока действия). Открытый интерес - технический индикатор, значение которого равно количеству действующих опционов, расчет по которым ещё не был произведён."
      },
      "volAvg": {
        "short": "Vol Avg",
        "full": "Volume Avg (30-Day)",
        "description": "средний объем по всем опционным контрактам (по всем датам экспирации) за последние 30 дней."
      },
      "todOi": {
        "short": "Tod OI",
        "full": "Today's Open Interest (OI)",
        "description": "общий открытый интерес по всем опционным контрактам (по всем датам истечения срока действия). Открытый интерес - технический индикатор, значение которого равно количеству действующих опционов, расчет по которым ещё не был произведён."
      },
      "oiAvg": {
        "short": "OI Avg",
        "full": "Open Int (30-Day)",
        "description": "средний общий открытый интерес по всем опционным контрактам (по всем датам истечения срока действия) за последние 30 дней. Открытый интерес - технический индикатор, значение которого равно количеству действующих опционов, расчет по которым ещё не был произведён."
      },
      "pcr": {
        "short": "PCR",
        "full": "Put/Call Vol Ratio",
        "description": "общее соотношение объема Put/Call для всех опционных контрактов (по всем датам экспирации). Значение меньше 1 означает, что объём покупателей больше, чем продавцов."
      },
      "pcrOi": {
        "short": "PCR (OI)",
        "full": "Put/Call OI Ratio",
        "description": "коэффициент Put/Call открытого интереса для всех опционных контрактов (по всем датам истечения срока действия). Открытый интерес - технический индикатор, значение которого равно количеству действующих опционов, расчет по которым ещё не был произведён. Значение меньше 1 означает, что покупателей больше, чем продавцов."
      },
      "ivRank": {
        "short": "IV Rank",
        "full": "IV Rank",
        "description": "текущий IV по сравнению с самыми высокими и самыми низкими значениями за последний год. Если IV ранг равен 100%, это означает, что IV находится на самом высоком уровне за последний год."
      },
      "ivPc": {
        "short": "IV PC",
        "full": "IV Percentile",
        "description": "процентиль ожидаемой волатильности (IV). Процент дней с закрытием IV ниже текущего значения IV за предыдущий год. Высокий IV процентиль означает, что текущий IV находится выше, чем в течение большей части прошлого года."
      }
    }
  }
}
